<template>
  <div>
    <validation-observer ref="form">
      <b-card title="Novo Documento de Contrato">
        <documento :documentos-contrato="documentosContrato" />
      </b-card>
      <b-card title="Séries">
        <series :series-c="seriesContrato" />
      </b-card>
      <b-card>

        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol,

} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import documento from './Adicionar/Documento.vue'
import series from './Adicionar/Series.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    documento,
    series,
    BCard,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      seriesContrato: { series: [] },
      documentosContrato: {
        codigo: '',
        descricao: '',
        inactivo: 0,
      },
    }
  },
  created() {
    localize('pt', pt)
  },
  methods: {
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.guardarDocumentosContrato()
        }
      })
    },
    guardarDocumentosContrato() {
      axios.post('/api/v1/documentosContrato', this.documentosContrato)
        .then(res => {
          this.seriesContrato.idTipoDoc = res.data.id
          this.guardarSeriesContrato()
        })
    },

    guardarSeriesContrato() {
      axios.post('/api/v1/seriesContrato/series', this.seriesContrato)
        .then(this.showToast('success', 'CheckIcon'))
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },

  },
}
</script>

<style></style>
