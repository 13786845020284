<template>
  <b-form @submit.prevent>
    <validation-observer ref="form">
      <b-row>
        <!-- Código -->
        <b-col
          md="3"
          lg="3"
        >
          <b-form-group
            label="Código"
            label-for="h-codigo"
          >
            <validation-provider
              #default="{ errors }"
              name="Código"
              rules="required"
            >
              <b-form-input
                id="h-codigo"
                v-model="documentosContrato.codigo"
                placeholder="Código"
                maxlength="15"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- Empresa -->
        <b-col
          md="8"
          lg="8"
        >
          <b-form-group
            label="Descrição"
            label-for="h-descricao"
          >
            <validation-provider
              #default="{ errors }"
              name="Descrição"
              rules="required"
            >
              <b-form-input
                id="h-descricao"
                v-model="documentosContrato.descricao"
                placeholder="Descrição"
                maxlength="50"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="1"
          lg="1"
        >
          <b-form-group
            label="Inactivo"
            label-for="inactivo"
            label-class="mb-1"
          >
            <b-form-checkbox
              id="inactivo"
              v-model="documentosContrato.inactivo"
              value="1"
            />

          </b-form-group>
        </b-col>

      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    documentosContrato: Object,
  },
  data() {
    return {
      required,

    }
  },
  created() {
    localize('pt', pt)
  },

}
</script>
